export default {
  en: {
    cards_remaining: "cards remaining",
    remove_filters: "Remove Filters",
  },
  spa: {
    cards_remaining: "tarjetas restantes",
    remove_filters: "Quitar filtros",
  },
} as const
